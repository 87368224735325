import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for the Home Page (Index) */}
        <Route path="/" element={<StaticPage page="index" />} />

        {/* Routes for About and Services Pages */}
        <Route path="/about" element={<StaticPage page="about" />} />
        <Route path="/services" element={<StaticPage page="services" />} />

        {/* Handle unknown routes */}
        <Route path="*" element={<div>Page Not Found</div>} />
      </Routes>
    </Router>
  );
}

// StaticPage Component to handle loading static HTML files
const StaticPage = ({ page }) => {
  React.useEffect(() => {
    // Redirect to the static HTML file only if not on the home page
    if (page === "about" || page === "services") {
      window.location.href = `/${page}.html`;
    }
  }, [page]);

  // Return null for all pages since they are static
  return null;
};

export default App;
